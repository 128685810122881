import * as React from "react"

// Components
import ArtistItem from "./ArtistItem"
// Model
import { IArtist } from "models/Artist"
// Styles
import * as styles from "./ArtistsList.module.css"

type ArtistsListProps = {
  artists: IArtist[]
}

const ArtistsList: React.FC<ArtistsListProps> = ({ artists }) => {
  return (
    <>
      <h2 className="title">Artistas</h2>
      <div className={styles.artists}>
        {artists.map(artist => (
          <ArtistItem key={artist.slug} artist={artist} />
        ))}
      </div>
    </>
  )
}

export default ArtistsList
