import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Model
import { IArtist } from "models/Artist"
// Styles
import * as styles from "./ArtistItem.module.css"

type ArtistItemProps = {
  artist: IArtist
}

const ArtistItem: React.FC<ArtistItemProps> = ({ artist }) => {
  const { name, slug, avatar, youtube, spotify, twitter, instagram, facebook, line, website } = artist
  const artistAvatar = getImage(avatar.childImageSharp.gatsbyImageData)

  return (
    <div className={styles.artist_item} key={name}>
      {artistAvatar && (
        <Link to={`/artists/${slug}/songs`}>
          <GatsbyImage className={styles.artist_item__avatar} image={artistAvatar} alt={name} />
        </Link>
      )}
      <h3 className={styles.artist_item__name}>
        <Link to={`/artists/${slug}/songs`}>{name}</Link>
      </h3>
      <main className={styles.artist_item__social_section}>
        {youtube && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.artist_item__social}
            href={youtube}
            aria-label="Youtube"
          >
            <FontAwesomeIcon icon={["fab", "youtube"]} />
          </a>
        )}
        {spotify && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.artist_item__social}
            href={spotify}
            aria-label="Spotify"
          >
            <FontAwesomeIcon icon={["fab", "spotify"]} />
          </a>
        )}
        {twitter && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.artist_item__social}
            href={`https://twitter.com/${twitter}`}
            aria-label="Twitter"
          >
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </a>
        )}
        {instagram && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.artist_item__social}
            href={`https://instagram.com/${instagram}`}
            aria-label="Instagram"
          >
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </a>
        )}
        {facebook && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.artist_item__social}
            href={`https://facebook.com/${facebook}`}
            aria-label="Facebook"
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </a>
        )}
        {line && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.artist_item__social}
            href={`${line}`}
            aria-label="Line"
          >
            <FontAwesomeIcon icon={["fab", "line"]} />
          </a>
        )}
        {website && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.artist_item__social}
            href={website}
            aria-label="Página web"
          >
            <FontAwesomeIcon icon={["fas", "globe"]} />
          </a>
        )}
      </main>
      <footer className={styles.artist_item__footer}>
        <Link className={styles.artist_item__view_songs} to={`/artists/${slug}/songs`}>
          Ver canciones
        </Link>
      </footer>
    </div>
  )
}

export default ArtistItem
