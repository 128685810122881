import * as React from "react"
import { graphql, PageProps } from "gatsby"

// Components
import Layout from "components/Layout"
import Main from "components/Layout/Main"
import DefaultSidebar from "components/Sections/Sidebar/DefaultSidebar"
import ArtistsList from "components/Sections/Artists/ArtistsList"
import Pagination from "components/UIElements/Pagination"
// Model
import { IArtist } from "models/Artist"
import { IOptionalMetaProps } from "models/Seo"

type DataProps = {
  artists: {
    nodes: IArtist[]
  }
}

type PageContextProps = {
  currentPage: number
  totalPages: number
}

const BlogIndex: React.FC<PageProps<DataProps, PageContextProps>> = ({ data, pageContext }) => {
  const { currentPage, totalPages } = pageContext
  const artists = data.artists.nodes

  const seo: IOptionalMetaProps = {
    title: "Artistas",
    description:
      "En esta página figura la lista de artistas de los cuales hemos traducido al menos una canción o un cover.",
  }

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <ArtistsList artists={artists} />
        <Pagination url="/artists" currentPage={currentPage} totalPages={totalPages} />
      </Main>
      <DefaultSidebar />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query ArtistsListQuery($limit: Int!, $skip: Int!) {
    artists: allArtist(filter: { hide: { ne: true } }, sort: { fields: name, order: ASC }, limit: $limit, skip: $skip) {
      nodes {
        name
        slug
        twitter
        facebook
        instagram
        youtube
        line
        spotify
        website
        avatar {
          childImageSharp {
            gatsbyImageData(width: 175, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
